.footer {
  margin-top: 80px;
  background-color: var(--color-green);
  color: var(--color-white);
  font-size: 12px;
}

.wrapper {
  padding: 32px 0;
  display: flex;
  flex-direction: row;
  gap: 40px;
  @media screen and (max-width: 600px) {
    padding: 24px 0;
    flex-direction: column-reverse;
    gap: 32px;
  }
  .logos {
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: center;
    img {
      display: block;
    }
  }
  .content {
    a {
      color: var(--color-white);
    }
  }
}